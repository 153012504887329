import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../layout/layout";
import Header from "../components/header";
import TextBlock from "../components/textblock";
import Image from "../components/image";
import Video from "../components/video";
import UnorderedList from "../components/unorderedlist";
import SEO from "../components/seo";

export const query = graphql`
    query ($slug: String) {
        sanityWorkshop(workshop_url: { current: { eq: $slug } }) {
            workshop_date(formatString: "dddd DD MMMM YYYY")
            workshop_description {
                children {
                    text
                }
            }
            workshop_image {
                asset {
                    gatsbyImageData
                }
            }
            workshop_end_time
            workshop_length
            workshop_leader
            workshop_name
            workshop_url {
                _key
                _type
                current
            }
            workshop_start_time
            sections {
                __typename
                ...ImageBlock
                ...TextBlockSection
                ... on SanityVideo {
                    _key
                    _type
                    videoTitle
                    videoUrl
                }
                ...Header
                ...UnorderedListSection
            }
            tags {
                project_tag
            }
            subject {
                subject_tag
            }
            formlink
        }
    }
`;

const WorkshopTemplate = ({ data }) => {
    const sections = data.sanityWorkshop.sections;

    return (
        <>
            <Layout>
                <SEO
                    title={data.sanityWorkshop.workshop_name}
                    canonical={"workshop/" + data.sanityWorkshop.workshop_url.current}
                />
                <div className="workshop-banner pt-24 pb-36">
                    <div className="container w-full sm:w-5/6 md:w-2/3 mx-auto flex flex-wrap px-6">
                        {data.sanityWorkshop.tags
                            .filter((x) => !!x)
                            .map((tag) => (
                                <span className="impact-title">{tag.project_tag}</span>
                            ))}
                        {data.sanityWorkshop.subject
                            .filter((x) => !!x)
                            .map((tag) => (
                                <span className="impact-title">{tag.subject_tag}</span>
                            ))}
                        <span className="impact-title">Workshop</span>

                        <h1 className="heading w-full py-4 mb-0">
                            {data.sanityWorkshop.workshop_name}
                        </h1>
                        <div className="w-full flex flex-wrap pb-4">
                            {data.sanityWorkshop.workshop_date ? (
                                <div className="pr-4">
                                    <b>Launching:</b> {data.sanityWorkshop.workshop_date}
                                </div>
                            ) : null}
                            {data.sanityWorkshop.workshop_leader ? (
                                <div className="pr-4">
                                    <b>Leader:</b> {data.sanityWorkshop.workshop_leader}
                                </div>
                            ) : null}
                            {data.sanityWorkshop.workshop_length ? (
                                <div className="pr-4">
                                    <b>Duration:</b> {data.sanityWorkshop.workshop_length}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <main
                    id="main-content"
                    className="container w-full sm:w-5/6 md:w-2/3 mx-auto flex flex-wrap -m-28 p-6"
                >
                    {data.sanityWorkshop.workshop_image ? (
                        <GatsbyImage
                            className="workshop-image"
                            image={data.sanityWorkshop.workshop_image.asset.gatsbyImageData}
                            alt={data.sanityWorkshop.workshop_name}
                        />
                    ) : null}
                    {data.sanityWorkshop.workshop_description.map((block) =>
                        block.children.map((content, i) =>
                            content.text ? <p key={i}>{content.text}</p> : ""
                        )
                    )}
                    {sections.map((section) => {
                        const typeName = section.__typename;

                        switch (typeName) {
                            case "SanityHeader":
                                return <Header key={section._key} {...section} />;
                            case "SanityTextBlock":
                                return <TextBlock key={section._key} {...section} />;
                            case "SanityImageBlock":
                                return <Image key={section._key} {...section} />;
                            case "SanityVideo":
                                return <Video key={section._key} {...section} />;
                            case "SanityUnorderedList":
                                return <UnorderedList key={section._key} {...section} />;
                            default:
                                return <p>Nothing to see here</p>;
                        }
                    })}
                    <div className="container mx-auto mb-24">
                        <a
                            href={data.sanityWorkshop.formlink}
                            className="button"
                            target="_blank"
                            rel="nofollow noreferrer"
                        >
                            Sign up for this workshop
                        </a>
                    </div>
                </main>
            </Layout>
        </>
    );
};

export default WorkshopTemplate;
